/* eslint-disable react/jsx-curly-newline */
/* eslint-disable nonblock-statement-body-position */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// eslint-disable-next-line import/no-named-as-default
import InfoBox from './InfoBox';
import SubmitBox from './SubmitBox';
import DateBox from './DateBox';

const MonthPicker = ({
  startText,
  endText,
  buttonText,
  submitLink,
  helpButtonText,
  helpHtmlTitle,
  modalElementId,
  modalLinkElementId,
  cssClass,
}) => {
  const { start, end } = useSelector((state) => state);
  return (
    <>
      <div className={`monthpicker-container ${cssClass}`}>
        <div className="month-picker">
          <InfoBox
            buttonText={helpButtonText}
            titleHtml={helpHtmlTitle}
            modalElementId={modalElementId}
            link={`${submitLink.trim()}?start=${start.timestamp}&end=${
              end.timestamp
            }`}
            modalLinkElementId={modalLinkElementId}
          />
          <DateBox startText={startText} endText={endText} />
          <SubmitBox
            submitLink={`${submitLink.trim()}?start=${start.timestamp}&end=${
              end.timestamp
            }`}
            buttonText={buttonText}
          />
        </div>
      </div>
    </>
  );
};

MonthPicker.defaultProps = {
  cssClass: '',
};

MonthPicker.propTypes = {
  startText: PropTypes.string.isRequired,
  endText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  submitLink: PropTypes.string.isRequired,
  helpButtonText: PropTypes.string.isRequired,
  helpHtmlTitle: PropTypes.string.isRequired,
  modalElementId: PropTypes.string.isRequired,
  modalLinkElementId: PropTypes.string.isRequired,
  cssClass: PropTypes.string,
};

export default MonthPicker;
