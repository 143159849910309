import React from 'react';
import PropTypes from 'prop-types';

const SubmitBox = ({ submitLink, buttonText }) => {
  return (
    <div className="box">
      <a
        className="submit-button"
        href={submitLink}
        target="_blank"
        rel="noreferrer"
      >
        <div className="text">{buttonText}</div>
        <div className="right-arrow" />
      </a>
    </div>
  );
};
SubmitBox.propTypes = {
  buttonText: PropTypes.string.isRequired,
  submitLink: PropTypes.string.isRequired,
};
export default SubmitBox;
