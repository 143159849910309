import { createStore } from 'redux';
import { reducer } from './date';
import { getDateObject, getFirstValidDate, endDate } from '../utils/dateFunctions';

// const start = startDate();
const start = getFirstValidDate();
const end = endDate(start);

const initialState = {
  start: getDateObject(start, false),
  end: getDateObject(end, true)
};


export default createStore(reducer, initialState);
