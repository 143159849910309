/* eslint-disable indent */
import { useTransition, animated } from 'react-spring';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const SliderBox = ({ item, order, cssClass }) => {
  const [values, setValues] = useState([
    { value: item, key: order },
    { value: item, key: order },
  ]);

  useEffect(() => {
    if (item !== values[values.length - 1].value) {
      setValues([
        values[values.length - 1],
        {
          value: item,
          key: order,
        },
      ]);
    }
  }, [item, order, values]);
  // eslint-disable-next-line operator-linebreak
  const cssProps =
    values[0].key && values.length > 1 && values[0].key <= values[1].key
      ? {
          from: { transform: 'translate3d(0px, 35px,0px)', opacity: 0 },
          enter: { transform: 'translate3d(0px,0px,0px)', opacity: 1 },
          leave: { transform: 'translate3d(0px,-35px,0px)', opacity: 0 },
        }
      : {
          from: { transform: 'translate3d(0px,-35px,0px)', opacity: 0 },
          enter: { transform: 'translate3d(0px,0px,0px)', opacity: 1 },
          leave: { transform: 'translate3d(0px,35px,0px)', opacity: 0 },
        };

  const transition = useTransition(values[values.length - 1], {
    keys: (itm) => itm.key,
    ...values[values.length - 1],
    ...cssProps,
  });

  return (
    <div className={`slide-item ${cssClass}`}>
      {transition((s, element) => {
        const { transform, opacity } = s;

        return (
          <animated.div style={{ opacity, transform }} key={element.key}>
            {element.value}
          </animated.div>
        );
      })}
      {/* {item} */}
    </div>
  );
};
SliderBox.propTypes = {
  item: PropTypes.string.isRequired,
  cssClass: PropTypes.string.isRequired,
  order: PropTypes.number.isRequired,
};
export default SliderBox;
