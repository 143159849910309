import { DateTime } from 'luxon';

const locale = 'it-IT';

const getTimeFragments = (date) => [date.year, date.month, date.day, date.hour, date.minute, date.second, date.millisecond, { zone: 'Europe/Rome' }];

/**
 * returns the DateTime object with the start or the end of the month
 * @param {*} date
 * @param {*} isEnd if true gives you the last day of the month otherwise the first day
 */
export const getStartOrEndMonth = (date, isEnd) => {
  const localeDate = DateTime.local(...getTimeFragments(date)).setLocale(locale);
  return isEnd ? localeDate.endOf('month') : localeDate.startOf('month');
};

/**
 * returns the Datetime Object of the next month
 * @param {*} date
 * @param {*} monthsToAdd
 * @param {*} lastDayOfMonth if true gives you the last day of the month otherwise the first day
 */
export const getNextMonth = (date, monthsToAdd = 1, lastDayOfMonth) => {
  const nextMonth = date.plus({ months: monthsToAdd });
  return lastDayOfMonth
    ? getStartOrEndMonth(nextMonth, true)
    : getStartOrEndMonth(nextMonth, false);
};

/**
 * returns the Datetime Object of the next month
 * @param {*} date
 * @param {*} month
 * @param {*} day if day is 0, null or undefined it'll give you the last day of the month
 */
export const setDayAndMonth = (date, month, day) => {
  if (month === 0) {
    month = 12;
    date = date.minus({ year: 1 });
  }
  if (month === 13) {
    month = 1;
    date = date.plus({ year: 1 });
  }
  if (!day) {
    return DateTime.local(
      date.year,
      month,
      1,
      date.hour,
      date.minute,
      date.second,
      date.millisecond,
    )
      .setLocale(locale)
      .endOf('month');
  }
  return date.set({ month, day });
};

/**
 * returns a Datetime Object with the next date
 * @param {*} date
 * @param {*} days
 * @param {*} lastDayOfMonth if true gives you the last day of the month otherwise the first day
 */
export const getNextDay = (date, days, lastDayOfMonth = false) => {
  const nextDay = lastDayOfMonth
    ? getStartOrEndMonth(date, true)
    : getStartOrEndMonth(date, false);
  return nextDay.set({ days });
};

/**
 * returns a Datetime Object with the previous date
 * @param {*} date
 * @param {*} days
 * @param {*} lastDayOfMonth if true gives you the last day of the month otherwise the first day
 */
export const getPreviousDay = (date, days, lastDayOfMonth = false) => {
  const nextDay = lastDayOfMonth
    ? getStartOrEndMonth(date, true)
    : getStartOrEndMonth(date, false);
  return nextDay.minus({ days });
};

/**
 * returns the Datetime Object of the previous month
 * @param {*} date
 * @param {*} monthsToAdd
 * @param {*} lastDayOfMonth if true gives you the last day of the month otherwise the first day
 */
export const getPreviousMonth = (date, monthsToRemove = 1, lastDayOfMonth) => {
  const previousMonth = date.minus({ months: monthsToRemove });
  return lastDayOfMonth
    ? getStartOrEndMonth(previousMonth, true)
    : getStartOrEndMonth(previousMonth, false);
};
/**
 * returns the Datetime Object of next year
 * @param {*} date
 * @param {*} lastDayOfMonth if true gives you the last day of the month otherwise the first day
 * @param {*} keepMonth if true keeps the month as the input otherwise one month before
 */
export const getNextYear = (date, lastDayOfMonth = true, keepMonth) => {
  let nextYear = lastDayOfMonth
    ? getStartOrEndMonth(date, true)
    : getStartOrEndMonth(date, false);
  nextYear = nextYear.plus({ years: 1 });
  return keepMonth ? nextYear : nextYear.plus({ month: 1 });
};

export const getNextYearWithDay = (date) => {
  return date.plus({ year: 1 });
};

/**
 * returns the Datetime Object of previous year
 * @param {*} date
 * @param {*} years years to add; default is one
 * @param {*} lastDayOfMonth if true gives you the last day of the month otherwise the first day
 * @param {*} keepMonth if true keeps the month as the input otherwise one month before
 */
export const getPreviousYear = (date, lastDayOfMonth = true, keepMonth) => {
  let previousYear = lastDayOfMonth
    ? getStartOrEndMonth(date, true)
    : getStartOrEndMonth(date, false);
  previousYear = previousYear.minus({ years: 1 });
  return keepMonth ? previousYear : getStartOrEndMonth(previousYear, true);
};

export const getPreviousYearWithDay = (date) => {
  return date.minus({ year: 1 });
};

/**
 * returns the Datetime Object
 * @param {*} date
 * @param {*} lastDayOfMonth if true gives you the last day of the
 * month otherwise the first ; default is true
 * @param {*} locale the language option; default is it-IT
 */
export const getDateObject = (date, lastDayOfMonth = true) => {
  const nextMonth = getNextMonth(date, 1, lastDayOfMonth);
  const previousMonth = getPreviousMonth(date, 1, lastDayOfMonth);
  const nextYear = getNextYear(date, lastDayOfMonth);
  const previousYear = getPreviousYear(date, lastDayOfMonth, true);

  const yearOption = {
    year: 'numeric',
  };

  const monthOption = {
    month: 'long',
  };

  return {
    timestamp: date.toMillis(),
    date,
    day: date.day,
    previousMonth: {
      timestamp: previousMonth.toMillis(),
      value: DateTime.local(...getTimeFragments(previousMonth)).toLocaleString({ month: 'long' }),
    },
    month: {
      value: DateTime.local(...getTimeFragments(date)).toLocaleString({ month: 'long' }),
    },
    nextMonth: {
      timestamp: nextMonth.toMillis(),
      value: DateTime.local(...getTimeFragments(nextMonth)).toLocaleString({ month: 'long' }),
    },
    previousYear: {
      timestamp: previousYear.toMillis(),
      value: DateTime.local(...getTimeFragments(getPreviousYear(date))).toLocaleString({ year: 'numeric' }),
    },
    year: {
      value: DateTime.local(...getTimeFragments(date)).toLocaleString({ year: 'numeric' }),
    },
    nextYear: {
      timestamp: nextYear.toMillis(),
      value: DateTime.local(...getTimeFragments(getNextYear(date))).toLocaleString({ year: 'numeric' }),
    },
  };
};

/**
 * returns the year different between two Datetime Objects
 * @param {*} begin the starting Datetime Object
 * @param {*} end the ending Datetime Object
 */
export const getYearsDiff = (start, finish) => {
  return finish.year - start.year;
};
/**
 * returns the  month different between two Datetime Objects
 * @param {*} begin the starting Datetime Object
 * @param {*} end the ending Datetime Object
 */
export const getMonthsDiff = (start, finish) => {
  const years = getYearsDiff(start, finish);
  const monthsDiff = finish.month - start.month;
  return years * 12 + monthsDiff;
};
/**
 *  returns a boolean value if the difference between
 *  the begining and end date is valid
 * @param {*} start start Datetime Object
 * @param {*} finish end Datetime Object
 * @param {*} VALID_MONTHS_DIFFERENCE the month inteval difference
 */
export const isValidEnd = (start, finish, VALID_MONTHS_DIFFERENCE = 10) => {
  return getMonthsDiff(start, finish) >= VALID_MONTHS_DIFFERENCE;
};

/**
 *  returns a boolean value if the difference between
 *  the begining and end date is valid
 * @param {*} start start Datetime Object
 * @param {*} finish end timestamp
 * @param {*} finishDay end day Datetime Object
 * @param {*} VALID_MONTHS_DIFFERENCE the month inteval difference
 */

export const isValidEndWithMidMonth = (
  start,
  finish,
  finishDay,
  VALID_MONTHS_DIFFERENCE = 11,
) => {
  let finishDate = DateTime.fromMillis(finish);
  if (finishDay != (31 || 30 || 29 || 28 || 27)) finishDate = finishDate.set({ day: finishDay });
  if (getMonthsDiff(start, finishDate) > VALID_MONTHS_DIFFERENCE) {
    return true;
  } if (getMonthsDiff(start, finishDate) === VALID_MONTHS_DIFFERENCE) {
    if (start.day === 15 && finishDate.day === 14) {
      return false;
    } if (
      start.day === 1
      && (finishDate.day === 31
        || finishDate.day === 30
        || finishDate.day === 29
        || finishDate.day === 28
        || finishDate.day === 27
        || finishDate.day === 1)
    ) {
      return true;
    } return false;
  } return false;
};

/**
 *  returns the initialMonth
 */
export const calculateInitialDate = () => {
  const now = DateTime.now();
  return now.day < 11 ? 2 : 3;
  // if (now.month !== 6 || 7 || 8) {
  //   return now.day === 1 ? 3 : 4;
  // } else {
  //   return now.day === 1 ? 3 : 4;
  // }
};

/**
 *  returns a boolean value if starting day is not in the past
 * @param {*} start start Datetime Object
 */
export const isValidStart = (
  start,
  MONTHS_DIFFERENCE = calculateInitialDate(),
  caller,
) => {
  const now = DateTime.now();
  // return start.ts > now.ts && getMonthsDiff(now, start) >= MONTHS_DIFFERENCE;
  return start > now;
};

/**
 *  returns a boolean value if starting day is not in the past
 * @param {*} start start Datetime Object
 */
export const isValidStartMidMonth = (
  start,
  startDay,
  MONTHS_DIFFERENCE = calculateInitialDate(),
) => {
  const now = DateTime.now();
  let startDate = DateTime.fromMillis(start);
  startDate = startDate.set({ day: startDay });
  if (startDate > now) {
    // return getMonthsDiff(now, startDate) >= MONTHS_DIFFERENCE;
    return true;
  }
  return false;
};

export const startDate = () => {
  let now = DateTime.now();
  if (now.day < 11) {
    now = now.set({ month: now.month, day: 15 });
    return now.plus({ months: 2 });
  } if (now.day >= 11 && now.day < 26) {
    now = now.set({ month: now.month, day: 1 });
    now = now.plus({ months: 3 });
    return getStartOrEndMonth(now, false);
  }
  now = now.set({ month: now.month, day: 15 });
  return now = now.plus({ months: 3 });
};

export const endDate = (startDate) => {
  if (startDate.day === 15) {
    startDate = startDate.set({ month: startDate.month, day: 14 });
    return startDate.plus({ months: 11 });
  }
  startDate = startDate.set({ month: startDate.month, day: 1 });
  startDate = startDate.plus({ months: 11 });
  return getStartOrEndMonth(startDate, true);
};

export const getFirstValidDate = () => {
  let firstValidDate = DateTime.now();
  if (firstValidDate.day < 15) {
    firstValidDate = firstValidDate.set({ month: firstValidDate.month, day: 15 });
  } else {
    firstValidDate = firstValidDate.set({ month: firstValidDate.month, day: 1 });
    firstValidDate = firstValidDate.plus({ months: 1 });
  }
  return firstValidDate;
};

export const isValidStartDay = (start) => {
  const now = DateTime.now();
  let stDate = start;
  if (start.day === 15) {
    stDate = stDate.set({ day: 1 });
  } else {
    stDate = stDate.minus({ months: 1 });
    stDate = stDate.set({ day: 15 });
  }
  return stDate > now;
};

export default {
  getNextMonth,
  getNextYear,
  getNextYearWithDay,
  getDateObject,
  getPreviousMonth,
  getPreviousYear,
  getYearsDiff,
  isValidEnd,
  isValidStart,
  setDayAndMonth,
  startDate,
  endDate,
  getFirstValidDate,
};
