import PropTypes from 'prop-types';
import React from 'react';

const Arrows = ({ onUp, onDown, isValidFunc }) => {
  return (
    <div className="arrow-container">
      <div
        className="arrow top-arrow active"
        aria-hidden
        onClick={() => onUp()}
      >
        <div className="arrow-image up" />
      </div>
      <div className="arrows-delimiter" />
      <div
        className={`arrow down-arrow ${isValidFunc() ? 'active' : ''}`}
        aria-hidden
        onClick={() => {
          if (isValidFunc()) {
            onDown();
          }
        }}
      >
        <div className="arrow-image down" />
      </div>
    </div>
  );
};
Arrows.propTypes = {
  onUp: PropTypes.func.isRequired,
  onDown: PropTypes.func.isRequired,
  isValidFunc: PropTypes.func.isRequired,
};
export default Arrows;
