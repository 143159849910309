import {
  getNextDay,
  getPreviousDay,
  getNextMonth,
  getPreviousMonth,
  getDateObject,
  getNextYear,
  isValidStart,
  isValidEnd,
  setDayAndMonth,
  getNextYearWithDay,
  getPreviousYearWithDay,
} from '../utils/dateFunctions';
// action Types
const SET_INCREASING_DAY = 'SET_INCREASING_DAY';
const SET_DECREASING_DAY = 'SET_DECREASING_DAY';

const SET_INCREASING_START_MONTH = 'SET_INCREASING_START_MONTH';
const SET_DECREASING_START_MONTH = 'SET_DECREASING_START_MONTH';
const SET_INCREASING_START_YEAR = 'SET_INCREASING_START_YEAR';
const SET_DECREASING_START_YEAR = 'SET_DECREASING_START_YEAR';

const SET_INCREASING_END_MONTH = 'SET_INCREASING_END_MONTH';
const SET_DECREASING_END_MONTH = 'SET_DECREASING_END_MONTH';
const SET_INCREASING_END_YEAR = 'SET_INCREASING_END_YEAR';
const SET_DECREASING_END_YEAR = 'SET_DECREASING_END_YEAR';

// action Creators
export const startDayIncreased = (day) => ({
  type: SET_INCREASING_DAY,
  payload: {
    day,
  },
});
export const startDayDecreased = (day) => ({
  type: SET_DECREASING_DAY,
  payload: {
    day,
  },
});
export const startMonthIncreased = (months) => ({
  type: SET_INCREASING_START_MONTH,
  payload: {
    months,
  },
});
export const endMonthIncreased = (months) => ({
  type: SET_INCREASING_END_MONTH,
  payload: {
    months,
  },
});
export const startMonthDecreased = (months) => ({
  type: SET_DECREASING_START_MONTH,
  payload: {
    months,
  },
});
export const endMonthDecreased = (months) => ({
  type: SET_DECREASING_END_MONTH,
  payload: {
    months,
  },
});

export const startYearIncreased = (years) => ({
  type: SET_INCREASING_START_YEAR,
  payload: {
    years,
  },
});
export const startYearDecreased = (years) => ({
  type: SET_DECREASING_START_YEAR,
  payload: {
    years,
  },
});
export const endYearIncreased = (years) => ({
  type: SET_INCREASING_END_YEAR,
  payload: {
    years,
  },
});
export const endYearDecreased = (years) => ({
  type: SET_DECREASING_END_YEAR,
  payload: {
    years,
  },
});

// reducer
export function reducer(state = [], action) {
  switch (action.type) {
    // start date
    case SET_INCREASING_DAY: {
      const begin = getNextDay(state.start.date, action.payload.day);
      const end = getDateObject(state.end.date);

      if (state.start.date.day === 1) {
        end.date = setDayAndMonth(end.date, end.date.month + 1, 14);
      } else {
        end.date = setDayAndMonth(end.date, end.date.month, 0);
      }

      return {
        start: isValidStart(begin) ? getDateObject(begin, false) : { ...state.start },
        end: !isValidEnd(begin, state.end.date) ? getDateObject(getNextYear(begin, true, false)) : getDateObject(end.date),
      };
    }
    case SET_DECREASING_DAY: {
      const begin = getNextDay(state.start.date, action.payload.day);

      if (state.start.date.day === 15) {
        state.end.date = setDayAndMonth(state.end.date, state.end.date.month - 1, 0);
      }

      return {
        start: isValidStart(begin) ? getDateObject(begin, false) : { ...state.start },
        end: !isValidEnd(begin, state.end.date) ? getDateObject(getNextYear(begin, true, false)) : getDateObject(state.end.date),
      };
    }
    case SET_INCREASING_START_MONTH: {
      const begin = getNextMonth(state.start.date, action.payload.months, false);

      return {
        start: getDateObject(begin),
        end: !isValidEnd(begin, state.end.date) ? getDateObject(getNextYear(begin, true)) : { ...state.end },
      };
    }
    case SET_DECREASING_START_MONTH: {
      const begin = getPreviousDay(state.start.date, action.payload.months, false);

      if (state.start.date.day === 1) {
        state.end.date = setDayAndMonth(state.end.date, state.end.date.month, 14);
      } else {
        state.end.date = setDayAndMonth(state.end.date, state.end.date.month - action.payload.months, 0);
      }

      return {
        start: isValidStart(begin) ? getDateObject(begin, false) : { ...state.start },
        end: !isValidEnd(begin, state.end.date) ? getDateObject(getNextYear(begin, true, false)) : { ...state.end },
      };
    }
    case SET_INCREASING_START_YEAR: {
      const begin = getNextYearWithDay(state.start.date);

      return {
        start: isValidStart(begin) ? getDateObject(begin, false) : getDateObject(state.start.date, false),
        end: !isValidEnd(begin, state.end.date) ? getDateObject(getNextYearWithDay(state.end.date)) : { ...state.end },
      };
    }
    case SET_DECREASING_START_YEAR: {
      const begin = getPreviousYearWithDay(state.start.date);

      return {
        start: isValidStart(begin) ? getDateObject(begin, false) : getDateObject(state.start.date, false),
        end: { ...state.end },
      };
    }
    // END DATE

    case SET_INCREASING_END_MONTH: {
      const end = getNextMonth(state.end.date, action.payload.months, true);

      if (state.start.date.day === 15) {
        state.end.date = setDayAndMonth(state.end.date, state.end.date.month + action.payload.months, 14);
      } else {
        state.end.date = setDayAndMonth(state.end.date, state.end.date.month + action.payload.months, 0);
      }

      return {
        start: { ...state.start },
        end: getDateObject(state.end.date),
      };
    }
    case SET_DECREASING_END_MONTH: {
      const end = getPreviousMonth(state.end.date, action.payload.months, true);

      if (state.start.date.day === 1) {
        state.end.date = setDayAndMonth(state.end.date, state.end.date.month - action.payload.months, 0);
      } else {
        state.end.date = setDayAndMonth(state.end.date, state.end.date.month - action.payload.months, 14);
      }

      return {
        start: { ...state.start },
        end: isValidEnd(state.start.date, state.end.date) ? getDateObject(state.end.date) : { ...state.end },
      };
    }
    case SET_INCREASING_END_YEAR: {
      const end = getNextYearWithDay(state.end.date);

      return {
        start: { ...state.start },
        end: getDateObject(end),
      };
    }
    case SET_DECREASING_END_YEAR: {
      const end = getPreviousYearWithDay(state.end.date);

      return {
        start: { ...state.start },
        end: isValidEnd(state.start.date, end) ? getDateObject(end) : { ...state.end },
      };
    }

    default:
      return state;
  }
}

export default { reducer };
