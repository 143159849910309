import React from 'react';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  startDayDecreased,
  startDayIncreased,
  startMonthIncreased,
  startMonthDecreased,
  startYearIncreased,
  startYearDecreased,
  endMonthIncreased,
  endMonthDecreased,
  endYearIncreased,
  endYearDecreased,
} from '../store/date';
import { isValidEnd, isValidEndWithMidMonth, isValidStartDay, isValidStart } from '../utils/dateFunctions';
import DatePickerBox from './DatePickerBox';

const DateBox = ({ startText, endText }) => {
  const dispatch = useDispatch();
  const { start, end } = useSelector((state) => state);
  const increaseDay = (days) => dispatch(startDayIncreased(days));
  const decreaseDay = (days) => dispatch(startDayDecreased(days));
  const increaseStartMonth = () => dispatch(startMonthIncreased(1));
  const decreaseStartMonth = () => dispatch(startMonthDecreased(1));

  const increaseDayOrMonth = () => {
    if (start.day === 1) {
      increaseDay(15);
    } else {
      increaseDay(1);
      increaseStartMonth();
    }
  };

  const decreaseDayOrMonth = () => {
    if (start.day === 1) {
      decreaseStartMonth();
      decreaseDay(15);
    } else {
      decreaseDay(1);
    }
  };

  const dateBoxes = [
    {
      name: 'start-date',
      topNote: startText,
      month: {
        day: start.day,
        value: start.month.value,
        timestamp: start.timestamp,
        onUP: () => increaseDayOrMonth(),
        onDown: () => decreaseDayOrMonth(),
        // isValidFunc: () => isValidStartMidMonth(start.previousMonth.timestamp, start.day),
        isValidFunc: () => isValidStartDay(start.date),
      },
      year: {
        value: start.year.value,
        timestamp: start.timestamp,
        onUP: () => dispatch(startYearIncreased(1)),
        onDown: () => dispatch(startYearDecreased(1)),
        isValidFunc: () => isValidStart(DateTime.fromMillis(start.previousYear.timestamp)),
      },
    },
    {
      name: 'end-date',
      topNote: endText,
      month: {
        day: end.day,
        value: end.month.value,
        timestamp: end.timestamp,
        onUP: () => dispatch(endMonthIncreased(1)),
        onDown: () => dispatch(endMonthDecreased(1)),
        isValidFunc: () => isValidEndWithMidMonth(start.date, end.previousMonth.timestamp, end.day),
      },
      year: {
        value: end.year.value,
        timestamp: end.timestamp,
        onUP: () => dispatch(endYearIncreased(1)),
        onDown: () => dispatch(endYearDecreased(1)),
        isValidFunc: () => isValidEnd(start.date, DateTime.fromMillis(end.previousYear.timestamp)),
      },
    },
  ];

  return (
    <div className='box dates'>
      {dateBoxes.map((item) => (
        <div className={item.name} key={item.name}>
          <div className='top-note'>{item.topNote}</div>
          <div className='date-picker'>
            <DatePickerBox
              type='month'
              day={item.month.day}
              value={item.month.value}
              timestamp={item.month.timestamp}
              onUp={item.month.onUP}
              onDown={item.month.onDown}
              isValidFunc={item.month.isValidFunc}
            />
            <DatePickerBox
              type='year'
              value={item.year.value}
              timestamp={item.year.timestamp}
              onUp={item.year.onUP}
              onDown={item.year.onDown}
              isValidFunc={item.year.isValidFunc}
            />
            <div className='bottom-note' />
          </div>
        </div>
      ))}
    </div>
  );
};
DateBox.propTypes = {
  startText: PropTypes.string.isRequired,
  endText: PropTypes.string.isRequired,
};

export default DateBox;
