/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React from 'react';
import activateDomModal from '../utils/domFunctions';

const InfoBox = ({
  buttonText,
  titleHtml,
  modalElementId,
  modalLinkElementId,
  link,
}) => {
  return (
    <div className="help box">
      <div className="info">
        <div className="info-icon" />
        <div className="text" dangerouslySetInnerHTML={{ __html: titleHtml }} />
      </div>
      <div className="button">
        <div
          className="info-button"
          role="button"
          tabIndex={0}
          onKeyPress={(e) =>
            activateDomModal(e, modalElementId, link, modalLinkElementId)
          }
          onClick={(e) =>
            activateDomModal(e, modalElementId, link, modalLinkElementId)
          }
        >
          {buttonText}
        </div>
      </div>
    </div>
  );
};

InfoBox.propTypes = {
  buttonText: PropTypes.string.isRequired,
  titleHtml: PropTypes.string.isRequired,
  modalElementId: PropTypes.string.isRequired,
  modalLinkElementId: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default InfoBox;
