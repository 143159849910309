import React from 'react';
import PropTypes from 'prop-types';
import Arrows from './Arrows';
import SliderBox from './SliderBox';

const DatePickerBox = ({
  day,
  value,
  timestamp,
  onUp,
  onDown,
  isValidFunc,
  type,
}) => {
  return (
    <div className={type === 'month' ? 'month-box' : 'year-box'}>
      {type === 'month' && (
        <div className="day-container">
          <div className="day">{day}</div>
        </div>
      )}
      <SliderBox
        item={value}
        order={timestamp}
        cssClass={type === 'month' ? type : 'year-container'}
      />
      <Arrows onUp={onUp} onDown={onDown} isValidFunc={isValidFunc} />
    </div>
  );
};

DatePickerBox.defaultProps = {
  day: 0,
};
DatePickerBox.propTypes = {
  day: PropTypes.number,
  value: PropTypes.string.isRequired,
  timestamp: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  onUp: PropTypes.func.isRequired,
  onDown: PropTypes.func.isRequired,
  isValidFunc: PropTypes.func.isRequired,
};
export default DatePickerBox;
