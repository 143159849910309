/* eslint-disable no-undef */
export default function activateDomModal(
  e,
  modalElementId,
  url,
  modalLinkElementId,
) {
  e.preventDefault();
  // shows modal

  const element = document.getElementById(modalElementId);
  element.style.display = 'flex';
  element.style.opacity = 1;
  if (link) {
    const link = document.getElementById(modalLinkElementId);
    link.setAttribute('href', url);
  } else console.log('Link component not found!');
}
