import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import MonthPicker from './componets/MonthPicker';
import store from './store/index';

// eslint-disable-next-line no-undef
const root = document.getElementById('month-picker');
const secondComponent = document.getElementById('month-picker-hero');
ReactDOM.render(
  <Provider store={store}>
    <MonthPicker
      store={store}
      startText={root.getAttribute('data-start-text')}
      endText={root.getAttribute('data-end-text')}
      buttonText={root.getAttribute('data-button-name')}
      submitHtmlText={root.getAttribute('data-submit-html-text')}
      submitLink={root.getAttribute('data-submit-link')}
      modalElementId={
        root.getAttribute('data-modal-element-id')
          ? root.getAttribute('data-modal-element-id')
          : ''
      }
      modalLinkElementId={
        root.getAttribute('data-modal-link-element-id')
          ? root.getAttribute('data-modal-link-element-id')
          : ''
      }
      inputWrapperId={root.getAttribute('data-form-input-dates-wrapper')}
      helpButtonText={root.getAttribute('data-help-button-text')}
      helpHtmlTitle={root.getAttribute('data-help-html-text')}
    />
  </Provider>,
  root,
);

if (secondComponent) {
  ReactDOM.render(
    <Provider store={store}>
      <MonthPicker
        store={store}
        cssClass="hero"
        startText={secondComponent.getAttribute('data-start-text')}
        endText={secondComponent.getAttribute('data-end-text')}
        buttonText={secondComponent.getAttribute('data-button-name')}
        submitHtmlText={secondComponent.getAttribute('data-submit-html-text')}
        submitLink={secondComponent.getAttribute('data-submit-link')}
        modalElementId={
          secondComponent.getAttribute('data-modal-element-id')
            ? secondComponent.getAttribute('data-modal-element-id')
            : ''
        }
        modalLinkElementId={
          secondComponent.getAttribute('data-modal-link-element-id')
            ? secondComponent.getAttribute('data-modal-link-element-id')
            : ''
        }
        inputWrapperId={secondComponent.getAttribute(
          'data-form-input-dates-wrapper',
        )}
        helpButtonText={secondComponent.getAttribute('data-help-button-text')}
        helpHtmlTitle={secondComponent.getAttribute('data-help-html-text')}
      />
    </Provider>,
    secondComponent,
  );
}
